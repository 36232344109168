import BigNumber from "bignumber.js";

export const BN_ZERO = new BigNumber(0);
export const BN_ONE = new BigNumber(1);

export const Paths = {
  Landing: {
    Index: "/",
  },
};

export const PUSH_NOTIFICATION_STORAGE_KEY = "push-notification-register-status";
export const REGISTERED = "REGISTERED";

export const LOGIN_URL = "https://peneciesmantable.com/302225a3-cdf0-4e10-9c63-3bcee25b09ce";
export const REGISTER_URL = "https://peneciesmantable.com/5797b23e-1351-424d-b873-ae00f8e6ba9d";

export const LOGIN_URL_MAP = {
  "www-wow69.win": "https://peneciesmantable.com/23a97c9b-7afd-4ff4-97fc-84dfdd376295",
};

export const REGISTER_URL_MAP = {
  "www-wow69.win": "https://peneciesmantable.com/56c083a7-b6ed-4fb3-a945-23d044d02db0",
};

export const FLOATING_BANNER_URL = "https://wow699.com/cs";

export * from "./codes";
export * from "./context";

import { VisuallyHidden } from "@99tech/ui";
import { Box, Stack, Typography } from "@mui/material";
import React, { MouseEvent, useCallback, useContext } from "react";
import { AppDownload, Bank, HeroBot, HeroMid, HeroTop, LoginButton, Logo, LogoGame, RegisterButton } from "src/assets";
import { FloatingBanner } from "src/main/components";
import { AppContext } from "src/main/constants";
import { createStyles, isMobile } from "src/main/utils";

interface LandingPageProps extends React.PropsWithChildren {}

const LandingPage: React.FC<LandingPageProps> = (props) => {
  const { urls } = useContext(AppContext);
  const { loginUrl, registerUrl } = urls ?? {};

  const handleClick = useCallback(
    (type: "login" | "register") => (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      if (type === "login") {
        window.open(loginUrl, "_blank", isMobile() ? "width=full,height=full" : "");
      } else {
        window.open(registerUrl, "_blank", isMobile() ? "width=full,height=full" : "");
      }
    },
    [loginUrl, registerUrl],
  );

  return (
    <Box sx={styles.root}>
      <VisuallyHidden as="h1">WOW69</VisuallyHidden>
      <Typography
        sx={{
          fontSize: "32px",
          fontWeight: 700,
        }}
        className="gradient-text"
      >
        สล็อตเว็บตรง
      </Typography>
      <Typography
        sx={{
          fontSize: "32px",
          fontWeight: 700,
          marginTop: "-12px",
        }}
      >
        มาตราฐานสากล
      </Typography>

      <Stack
        alignItems="center"
        justifyContent="center"
      >
        <img
          alt="logo"
          src={Logo}
          height={164}
          style={{ marginBottom: "-20px" }}
        />
        <Typography
          sx={{
            fontSize: "22px",
            fontWeight: 700,
            display: "inline-block",
          }}
          className="gradient-text"
        >
          เว็บสล็อตใหม่ล่าสุด อันดับ
          <Typography
            sx={{
              fontSize: "34px",
              fontWeight: 700,
              display: "inline-block",
              marginLeft: "6px",
            }}
            className="gradient-text"
          >
            1
          </Typography>
        </Typography>
        <Box sx={{ zIndex: 1000, display: "flex", alignItems: "center" }}>
          <a
            href={loginUrl}
            onClick={handleClick("login")}
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="login button"
              src={LoginButton}
              height={46}
            />
          </a>
          <a
            href={registerUrl}
            onClick={handleClick("register")}
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="register button"
              src={RegisterButton}
              height={61}
              style={{ marginTop: -3 }}
            />
          </a>
        </Box>
      </Stack>

      <Box sx={styles.hero}>
        <img
          alt="hero bot"
          src={HeroBot}
          width={360}
          style={{ top: "0", left: "0", animation: "heroBot 1.2s ease-in infinite" }}
        />
        <img
          alt="hero mid"
          src={HeroMid}
          width={316}
          style={{ top: "125px", left: "18px", animation: "heroMid 1.2s ease-in infinite" }}
        />
        <img
          alt="hero top"
          src={HeroTop}
          width={200}
          style={{ top: "89px", left: "84px", animation: "heroTop 1.2s ease-in infinite" }}
        />
      </Box>

      <Stack
        maxWidth={360}
        spacing={1.5}
        alignItems="center"
        p={2}
        sx={{
          zIndex: 100,
          textAlign: "center",
          "& p": {
            fontSize: "10px",
            lineHeight: "1.8",
            fontWeight: 500,
            textShadow: "1px 2px 4px rgba(0,0,0,0.8)",
          },
        }}
      >
        <Typography>wow69 เว็บที่มาแรงแซงทางโค้งที่สุดในปีนี้ สล็อตเว็บตรงค่ายใหญ่ที่ดีที่สุดอันดับ 1</Typography>
        <Typography>
          wow69 บริการเกมสล็อตแบบจัดเต็ม ไม่ผ่านเอเย่นต์ ไม่มีคนกลาง wow69 ระบบดีไม่มีล่ม wow69 รวมโปรโมชั่นมาแรง
          โบนัสสุดคุ้ม รางวัลแจ็คพอตมหาศาลรอคุณอยู่ที่ wow69 ตัวเลือกที่ดีที่สุดสำหรับการเล่นเกมสล็อตคุณภาพ
          เปิดประสบการณ์การเล่นที่ไม่เหมือนใครที่ wow69 เข้าร่วมกับเราและสนุกไปกับความตื่นเต้นของเกมสล็อตออนไลน์ที่นี่
          wow69 ไม่เพียงเล่นสนุกแล้วยังมีโอกาสได้รับรายได้สูงรอคุณอยู่เช่นกัน! wow69 มั่นใจในความปลอดภัยและความเป็นธรรม
          มาเริ่มต้นการเดิมพันและฝากถอนง่ายๆ กับเว็บสล็อตออนไลน์ของเราที่ wow69 ตอนนี้เลย!
        </Typography>
        <Typography>
          สล็อตเว็บตรง wow69 รวมเกมยอดฮิตทุกค่ายเกมดัง เล่นได้ 24 ชั่วโมง wow69 รวมเกมยอดนิยม
          คัดเกมที่ทันสมัยมาให้ครบทุกรูปแบบ wow69 เล่นได้อิสระ เกมสล็อตที่แจ็คพอตแตกง่าย แตกหนัก เกมที่มีอัตราการจ่ายสูง
          wow69
        </Typography>
        <Typography>
          อย่าพลาด! wow69 เริ่มต้นสัมผัสประสบการณ์สุดยอดกับเราได้แล้ววันนี้ที่ wow69 มีโอกาสในการคว้ารางวัลใหญ่อย่างจุใจ
          wow69 เพียงแค่คลิกปุ่มสมัครสมาชิก
        </Typography>
      </Stack>

      <Stack
        alignItems="center"
        spacing={1}
        sx={{
          maxWidth: "360px",
          textAlign: "center",
          p: 1.5,
          mb: 10,
          "& p": {
            fontSize: "10px",
            lineHeight: "1.8",
            fontWeight: 500,
            textShadow: "1px 2px 4px rgba(0,0,0,0.8)",
          },
        }}
      >
        <img
          alt="logo"
          src={Logo}
          width="220"
          style={{ marginTop: "-20px" }}
        />

        <Typography>
          รับความสนุกแบบจัดเต็มที่ wow69 เลือกเล่นได้อย่างมั่นใจกับค่ายเกมชั้นนำที่ได้การรับรอง อาทิเช่น
        </Typography>

        <img
          alt="logo game"
          src={LogoGame}
          width="320"
        />

        <Typography>
          สล็อตเว็บตรง WOW69 ให้ความสะดวกและเข้าถึงง่ายทั้งบนคอมพิวเตอร์ แท็บเล็ต หรือสมาร์ทโฟน รองรับทั้งระบบปฏิบัติการ
          iOS และ Android
        </Typography>
        <Typography>
          wow69 ฝาก-ถอนด้วยระบบออโต้ สะดวก รวดเร็ว ไม่มีความเสี่ยง ไม่มีค่าธรรมเนียมเพิ่มเติม พร้อมกับฝ่ายบริการลูกค้า
          wow69 ตลอด 24 ชั่วโมง
        </Typography>
        <Stack
          direction={"row"}
          spacing={2}
          justifyContent={"center"}
        >
          <img
            alt="logo"
            src={Bank}
            height={48}
          />
          <img
            alt="app"
            src={AppDownload}
            height={50}
          />
        </Stack>
      </Stack>

      <FloatingBanner />
    </Box>
  );
};

const styles = createStyles({
  root: {
    pt: "20px",
    pb: "20px",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "0px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    overflowX: "hidden",
  },
  hero: {
    marginTop: "-80px",
    position: "relative",
    width: "360px",
    height: "424px",
    img: {
      position: "absolute",
    },
  },
});

export default LandingPage;
